import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { ModelList } from "../models/models-list";
import { LogListItemModel } from "../models/admin-settings/log-list-item.model";
import { LogListItemFilter } from "../models/admin-settings/log-list-item-filter.model";

@Injectable()
export class LogService extends BaseService {
    private restService = inject(HttpRestService);

    constructor() {
        super();
    }

    public async getLogs(filter: LogListItemFilter): Promise<ModelList<LogListItemModel>> {
        const data = await this.handleResponse(this.restService.post({
            url: `/log/get-logs`,
            data: filter
        }));

        const items = data.items.map(x => new LogListItemModel(x));
        return new ModelList<LogListItemModel>(items, data.totalCount);
    }
}
